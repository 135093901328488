import React from "react"
import { Helmet } from "react-helmet"
import IHead from "./types"

const Head = ({ pageTitle, description, keywords }: IHead) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <title>{"Click4Fit  - " + pageTitle}</title>
      <link rel="canonical" href="http://mysite.com/example" />
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Teko:wght@500;600&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  )
}

export default Head
