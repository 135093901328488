import styled from "styled-components"

export const Section = styled.section`
  width: 100%;
`
export const Container = styled.div`
  width: 1320px;
  @media (max-width: 1320px) {
    width: 100%;
  }
`

export const M = styled.div`
  margin: 15px;
`

export const GreenSpan = styled.span`
  color: ${props => props.theme.color.lightGreen};
`
export const BlackSpan = styled.span`
  color: ${props => props.theme.color.black};
`

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const FlexCenterRow = styled(FlexCenter)`
  flex-direction: row;
`
export const FlexCenterCol = styled(FlexCenter)`
  flex-direction: column;
`

export const FlexAlignCenterRow = styled.div`
  display: flex;
  align-items: center;
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`
export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`

export const GrayBorder = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.color.gray};
`

export const BlackBorder = styled(GrayBorder)`
  background-color: block;
`

export const H2Title = styled.h2`
  font-size: ${props => props.theme.fontSize.medium};
  margin: 0;
  text-align: left;
`

export const H2WhiteTitle = styled(H2Title)`
  color: ${props => props.theme.color.white};
`
export const H2BlackTitle = styled(H2Title)`
  color: ${props => props.theme.color.black};
`

export const H2BlackTitleMargin = styled(H2BlackTitle)`
  margin: 50px 0 50px 0;
`

export const TripleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: ". . .";
  @media (max-width: 1100px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: ". .";
  }
  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: ".";
  }
`

export const PillTagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
export const PillTag = styled.div`
  padding: 5px 20px 5px 20px;
  border: 1px solid black;
  margin: 2px;
`
